import "../resources/css/main.scss";

const demoSrc = "https://www.youtube-nocookie.com/embed/5iigb7cuLlM?rel=0&autoplay=0"; //https://youtu.be/5iigb7cuLlM
let isFormSent = false;

document.addEventListener( 'wpcf7mailsent', function( event ) {
  const demoLink = document.querySelector('#demo-link');
  const demoSentForm = document.querySelector('#send-demo-form'); // input t:submit
  const demoView = document.querySelector('#view-demo'); // a, link pos envio

  demoLink.setAttribute( 'src', demoSrc );
  isFormSent = true;
  demoSentForm.classList.add( 'd-none' );
  demoView.classList.remove( 'd-none' );
}, false );
